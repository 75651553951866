import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const UnderConstruction = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 text-center position-relative bg-[#aee7ff] text-dark p-4" style={{ overflow: 'hidden' }}>
      {/* Fondo decorativo con emojis dispersos */}
      <div className="position-absolute w-100 h-100 d-flex flex-wrap justify-content-around align-items-center" style={{ opacity: 0.1, fontSize: '4rem', pointerEvents: 'none' }}>
        <span>🚀</span>
        <span>🎨</span>
        <span>💡</span>
        <span>🔧</span>
        <span>🌟</span>
      </div>
      
      <h1 className="display-4 fw-bold text-[#7062ff]">¡Estamos construyendo algo increíble! 🚀</h1>
      <p className="lead fw-medium mt-3 text-[#595959]">
        Pronto lanzaremos nuestro nuevo sitio web. Mientras tanto, síguenos en Instagram para estar al tanto de todas las novedades.
      </p>
      <a
        href="https://www.instagram.com/nettar.web/"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-lg mt-4 px-5 py-3 fw-bold shadow-lg text-white border-0"
        style={{ backgroundColor: '#52cdff' }}
      >
        📸 Síguenos en Instagram
      </a>
    </div>
  );
};

export default UnderConstruction;
