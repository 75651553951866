import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Instagram from "../components/Instagram";
import Portfolio from "../components/Portfolio";
import UnderConstruction from "../components/UnderConstruction";

const Home = () => {
  return process.env.REACT_APP_UNDERCONSTRUCTION === 'true' ? <UnderConstruction/> : (
    <>
      <Header />
      <main>
        <Banner />
        <Cards />
        <Instagram />
        <Portfolio />
        <Form />
      </main>
      <Footer />
    </>
  );
};

export default Home;
